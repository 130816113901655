import { render, staticRenderFns } from "./divisor.vue?vue&type=template&id=a20e4c5e&scoped=true&"
import script from "./divisor.vue?vue&type=script&async=true&lang=js&"
export * from "./divisor.vue?vue&type=script&async=true&lang=js&"
import style0 from "./divisor.vue?vue&type=style&index=0&id=a20e4c5e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a20e4c5e",
  null
  
)

export default component.exports