<template>
  <div>
    <div class="flip-card" :style="'height:'+ height">
      <v-card :class="'flip-card-inner'+tipo">
        <v-card-text class="flip-card-front">
          <v-img v-if="isImg" class="front" :src="front" :height="height"/>
          <v-icon v-else :size="size" class="gradient_icone">{{front}}</v-icon>
          <p>{{texto}}</p>
        </v-card-text>
        <v-card-text :class="'flip-card-back'+tipo">
          <slot></slot>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script async>
/* eslint-disable */
  export default {
    name: 'ImgCardFlip',
    props: ['front', 'back', 'tipo','height', 'isImg','size','hasText','texto'],
  }
</script>

<style scoped>
  .flip-card {
    width: 100%;
    perspective: 1000px; /* Remove this if you don't want the 3D effect */
  }

  /* This container is needed to position the front and back side */
  .flip-card-innerY,.flip-card-innerX {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }
  /* Do an horizontal flip when you move the mouse over the flip box container */
  .flip-card:hover .flip-card-innerY {
    transform: rotateY(180deg);
  }
  .flip-card:hover .flip-card-innerX {
    transform: rotateX(180deg);
  }

  /* Position the front and back side */
  .flip-card-front, .flip-card-backY, .flip-card-backX {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
  }

  /* Style the front side (fallback if image is missing) */
  .flip-card-front {
    background-color: #fff;
    color: black;
  }

  /* Style the back side */
  .flip-card-backY,.flip-card-backX {
    background: linear-gradient(128deg, rgb(255, 31, 34) 49%, rgb(252, 103, 43) 100%);
    color: white;
  }
  .flip-card-backY {
    transform: rotateY(180deg);
  }
  .flip-card-backX {
    transform: rotateX(180deg);
  }
</style>
