/* eslint-disable */
import Vue from 'vue'
import Router from 'vue-router'
import Dash from "./Dash";

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: '',
      component: Dash,
      children: [
        {
          path: '/',
          name: 'home',
          component: () => import(/* webpackChunkName: "Home" */ './views/home/Home.vue')
        }, {
          path: '/home',
          name: 'Home',
          component: () => import(/* webpackChunkName: "Home" */ './views/home/Home.vue')
        }
      ]
    },
    {
      path: '*',
      name: '404',
      component: () => import(/* webpackChunkName: "404" */ './views/404.vue')
    },
  ]
})
