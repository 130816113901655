<template>
  <div>
    <v-row justify="center" no-gutters>
      <v-col :cols="col">
        <div data-aos="zoom-in" class="progressAnimatedHome" :style="'height:' +height+'px'"></div>
      </v-col>
    </v-row>
  </div>
</template>

<script async>
/* eslint-disable */
  export default {
    name: 'Divisor',
    props: {
      col: {default: 4},
      height: {default: 4},
    },
  }
</script>

<style scoped>
  .progressAnimatedHome {
    width:100%;
    margin-bottom: 1px;
    background: linear-gradient(299deg, #EDA53E, #FC672B, #C9182A);
    background-size: 800% 800%;
    -webkit-animation: AnimationName 7s ease infinite;
    -moz-animation: AnimationName 7s ease infinite;
    animation: AnimationName 7s ease infinite;
  }

</style>
